import React from 'react';
import { Steps } from 'tyb';

class ActivitySteps extends React.Component {
  render() {
    const { current = 0, titles } = this.props;
    return (
        <Steps current={current}>
          {
            titles.map((item, index) => {
              return (<Steps.Step title={item} key={index} />);
            })
          }
        </Steps>
    )
  }
}

export default ActivitySteps;

