import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Button, Tip, PageLoading } from 'tyb';

const { viewAudit, viewAuditLog } = iceStarkData.store.get('commonAction')?.batchApply;
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';

@connect(
  state => ({
    viewAudit: state.batchApply.viewAudit,
    viewAuditLog: state.batchApply.viewAuditLog,
  }),
  {
    loadViewAudit: viewAudit.REQUEST,
    loadViewAuditLog: viewAuditLog.REQUEST,
  }
)
class ViewDialog extends React.Component {

  componentDidMount() {
    if (this.props.viewType === 'audit') {
      this.props.loadViewAuditLog(this.props.viewObject.logId);
    } else {
      this.props.loadViewAudit(this.props.viewObject.id);
    }
  }

  render() {
    const { handleCancel, viewAudit: vAudit, viewAuditLog, viewType = '' } = this.props;

    let viewAudit;
    if (viewType === 'audit') {
      viewAudit = viewAuditLog;
    } else {
      viewAudit = vAudit;
    }

    return (
      <Dialog
        title="查看码包详情"
        visible={true}
        onCancel={handleCancel}
        style={{ width: '347px' }}
        footerRender={() =>
          <div className="tc-15-rich-dialog-ft-btn-wrap">
            <Button className="weak-button" onClick={handleCancel}>关闭</Button>
          </div>}
      >
        {
          viewAudit && (viewAudit.applyStatus === 'RETURN' || viewAudit.operation === 'PROCESS_RETURN') ?
            <Tip hasClose={false} type="error">不通过原因: {viewAudit.rejectReason}</Tip> : ''
        }
        {
          viewAudit ?
            <div className="ViewDialog">
              <div className="">
                <span>码包名称</span><span style={{ marginLeft: '32px' }}>{viewAudit.name}</span>
              </div>
              <div className="item">
                <span>产品</span><span style={{ marginLeft: '56px' }}>{viewAudit.productName}</span>
              </div>
              <div className="item">
                <span>总码量</span><span style={{ marginLeft: '44px' }}>{viewAudit.totalTagCount}</span>
              </div>
              <div className="item">
                <span>每批码量</span>
                {viewAudit.combinationTagType == 'SINGLE_TAG' ?
                  <span style={{ marginLeft: '32px' }}>{parseInt(viewAudit.totalTagCount / viewAudit.batchs)}{viewAudit.totalTagCount % viewAudit.batchs !== 0 && '(不可平分的余量在最后一个文件中)'}</span>
                  :
                  <span style={{ marginLeft: '32px' }}>{parseInt(viewAudit.totalTagCount / viewAudit.unitCount) * parseInt(viewAudit.unitCount / viewAudit.batchs)}{(viewAudit.totalTagCount % viewAudit.unitCount !== 0 || viewAudit.unitCount / viewAudit.batchs !== 0) && '(不可平分的余量在最后一个文件中)'}</span>
                }
              </div>
              <div className="item">
                <span>码包批次数量</span><span style={{ marginLeft: '32px' }}>{viewAudit.batchs}</span>
              </div>
              {viewAudit.combinationTagType != 'SINGLE_TAG' && <div className="item">
                <span>总套数</span><span style={{ marginLeft: '32px' }}>{viewAudit.unitCount}</span>
              </div>}
              <div className="item">
                <span>使用码印刷规格名称</span><span style={{ marginLeft: '32px' }}>{viewAudit.eseConfigName}</span>
              </div>
              <div className="item">
                <span>申请人</span><span style={{ marginLeft: '44px' }}>{viewAudit.applyName}</span>
              </div>
              <div className="item">
                <span>申请时间</span><span style={{ marginLeft: '32px' }}>{formatDate(viewAudit.createdTime)}</span>
              </div>
              <div className="item">
                <span>审核人</span><span style={{ marginLeft: '44px' }}>{viewAudit.auditName}</span>
              </div>
              <div className="item">
                <span>码包接收人</span><span style={{ marginLeft: '20px' }}>{viewAudit.packetReceiver}</span>
              </div>
              <div className="item">
                <span>密码接收人</span><span style={{ marginLeft: '20px' }}>{viewAudit.passwordReceiver}</span>
              </div>
              <div className="item">
                <span>包材印刷厂</span><span style={{ marginLeft: '20px' }}>{viewAudit.factoryName}</span>
              </div>
              <div className="item">
                <span>成品灌装厂</span><span style={{ marginLeft: '20px' }}>{viewAudit.productFactoryName}</span>
              </div>
              <div className="item">
                <span>辅料编号</span><span style={{ marginLeft: '32px', wordBreak: 'break-all' }}>{viewAudit.excipientsNumber}</span>
              </div>
              <div className="item">
                <span>备注信息</span><span style={{ marginLeft: '32px', wordBreak: 'break-all' }}>{viewAudit.remark}</span>
              </div>
              <div className="item">
                <span>码用途</span><span style={{ marginLeft: '43px', wordBreak: 'break-all' }}>{enums.purposeType[viewAudit.purpose].name}</span>
              </div>
            </div>
            : <PageLoading />
        }

      </Dialog>
    )
  }
}

export default ViewDialog;