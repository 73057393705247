import React from 'react';
import { Button, Input, DateTimeRangePicker } from 'tyb'


 const SearchBar = props => {
   const { search, clear, changeSearchBox, searchText, dataRange, changeDataRange } = props;
   return (
    <div className="search-bar">
    <Button onClick={clear} className="weak-button" style={{marginRight: 0}}>清空</Button>
    <Button onClick={search} className="" >搜索</Button>
    <Input
      className="search-input-block" style={{marginRight: '10px', width: '180px'}}
      onChange={changeSearchBox}
      value={searchText}
      placeholder="输入码包名称/ID查询"
    />
    <div 
      className="search-input-block" 
      style={{marginRight: '10px', width: 'auto' }}
      >
      <DateTimeRangePicker 
        placeholder="选择申请的时间范围"
        value={dataRange}
        onChange={changeDataRange}
      />
    </div>
    

  </div>
   )
 } 

 export default SearchBar;